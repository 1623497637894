var filters = {
    init : function () {

        this.expandFiltersWatcher();
        this.enableFilter();
    },

    expandFiltersWatcher: function () {

        $( '.filter.selected' ).on( 'click', expandFilters );

    },
    enableFilter: function() {
        $('.filter.selected').click();
    }
};

function expandFilters() {

    $( '.filter.selected' ).unbind( 'click' );

    $( this ).addClass( 'active' );
    $( this ).removeClass( 'selected' );
    $( this ).closest( '.filters' ).addClass( 'expanded' );

    $( '.filter:not( .selected )' ).on( 'click', selectFilter );
}

function selectFilter () {

    $( '.filter:not( .selected )' ).unbind( 'click' );
    
    var categorySlug = $( this ).data( 'category-slug' );
    
    $( '.grid-item' ).each( function () {

        var categories = $( this ).attr( 'data-categories' ).trim().split( ' ' );

        if ( categories.indexOf( categorySlug ) >= 0 )
            $( this ).show();
        else
            $( this ).hide();

    } );

    $('.grid').masonry();

    $( '.filter' ).removeClass( 'selected, active' );
    $( this ).addClass( 'selected' );

    
    $( this ).closest( '.filters' ).removeClass( 'expanded' );
    
    $( '.filter.selected' ).on( 'click', expandFilters );

}

$( document ).ready( function () {

    filters.init();

} );