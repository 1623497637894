

var map = {

    parishesUrl: '',

    map: {},
    markers: [],
    parishes: [],
    googleMapsAutocompleteInput: false,
    activeWindow: false,

    _init: function() {

        var self = this;

        var startLat = parseFloat( $('.address').first().data('lat') ); 
        var startLng = parseFloat( $('.address').first().data('lng') );

        this.map = new google.maps.Map(document.getElementById('g-map'), {
            zoom: 16,
            center: {lat: startLat, lng: startLng},
            gestureHandling: 'cooperative',
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true  
        }); 

        this.map.mapTypes.set( 'styled_map' , this.getMapStyle() );
        this.map.setMapTypeId( 'styled_map' );

        $('.address').each( function ( index, elem ) {

            self.setMarker( $( elem ) );
        } );

    },

    setMarker: function( addressElem ) {

        var self = this;

        var lat = parseFloat( addressElem.data( 'lat' ) );
        var lng = parseFloat( addressElem.data( 'lng' ) );

        var img = {
            url: $( 'body' ).data( 'address' ) + 'dist/img/map-marker.png',
            size: new google.maps.Size(43,51),
            anchor: new google.maps.Point(22, 47)
        };

        var marker = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: self.map,
            title: addressElem.data( 'title' ),
            animation: google.maps.Animation.DROP,
            draggable: false,
            icon: img
        });
        
        return marker;       

    },

    getMapStyle: function() {

        return new google.maps.StyledMapType( mapColors );

    }

};



function initMap() {
    map._init();
}

$( document).ready(function () {

    if ( !$('#g-map').length )
        return;

    var mapScript = document.createElement('script');
    mapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLB3dWVA94pIo5iTIncfWBb6-WWtRWhlA&callback=initMap';

    document.head.appendChild( mapScript );

});

var mapColors = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f2f2f2"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "stylers": [
        {
          "color": "#eb594d"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#eb594d"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    }
  ];