
// // window.sr = ScrollReveal({
// //     reset: false,
// //     easing: 'ease-out'
// // });

// // sr.reveal( '.bg-image', {
// //     scale: 1.1,
// //     distance: 0,
// //     duration: 500
// // } );

// // sr.reveal( '.bg-image--content', {
// //     scale: 0.5,
// //     distance: 0,
// //     duration: 200,
// //     delay: 500
// // }, 50 );

// var reveal = {

//     elementWatcher: null,

//     init : function () {

//         this.addWatchers();

//     },

//     addWatchers: function () {

//         var self = this;

//         $( '.reveal' ).each( function () {

//             self.createNewWatcher( this );

//         } );
        
//     },

//     createNewWatcher: function ( elem ) {

//         var self = this;

//         var elementWatcher = scrollMonitor.create( elem );

//         elementWatcher.enterViewport( function() {

//             var elem = $( this.watchItem );

//             var delay = elem.data( 'delay' );

//             if ( delay )
//                 setTimeout( function () {
//                     self.animate( elem );
//                 }, delay );
//             else
//                 self.animate( elem );

//         } );
        
//     },

//     animate: function ( elem ) {

//         if ( elem.hasClass( 'animated' ) )
//             return;
        
//         elem.addClass( 'animated' );

//     }

// };

// $( document ).ready( function () {
    
//     reveal.init();

// } );

// // BANNER 
// var scene = document.getElementById('scene');

// if ( scene )
//     var parallax = new Parallax( scene, {
//         limitX: 10,
//         limitY: 10,
//         scalarX: 0.30,
//         scalarY: 0.30
//     } ); 