var videoPlayer = {

    _init: function() {

        var vids = $("body").find('.realization video'); 
        $.each(vids, function(){
            this.controls = false; 
            this.autoplay = true;
            this.loop = true;
        });

    },


};

$(document).ready(function() {

    videoPlayer._init();

});