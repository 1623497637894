$(document).ready(function(){
	
	$('.menu-toggle').click( function( event ) {
		
		event.stopPropagation();

		$('.hamburger').toggleClass('is-active');
		$('#myNav').toggleClass('is-active');
		$('body').toggleClass('no-scroll');
		$('header').toggleClass('open'); 
		
	}); 
	
});

