var projectRotator = {

    init : function () {

        this.initSlick();

    },

    initSlick : function () {

        var siteUrl = $( 'body' ).data( 'address' );

        $( '.selected-projects .image-rotator' ).slick({
            autoplay: false,
            autoplaySpeed: 3000,
            arrows: false,
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'ease-out',
            asNavFor: '.selected-projects .description-wrapper'
        });

        $( '.selected-projects .readme-rotator' ).slick({
            autoplay: false,
            autoplaySpeed: 3000,
            arrows: false,
            dots: false,
            infinite: true,
            speed: 1,
            fade: true,
            cssEase: 'linear',
            asNavFor: '.selected-projects .image-rotator'
        });

        $( '.selected-projects .description-wrapper' ).slick({
            autoplay: false,
            autoplaySpeed: 3000,
            arrows: true,
            dots: false,
            infinite: true,
            speed: 500,
            prevArrow: '<button type="button" class="slick-prev"><img class="svg" src="' + siteUrl + 'dist/img/chevron.svg" /></button>',
            nextArrow: '<button type="button" class="slick-next"><img class="svg" src="' + siteUrl + 'dist/img/chevron.svg" /></button>',
            asNavFor: '.selected-projects .image-rotator, .readme-rotator'
        });




    }

};

var whatDoWeDo = {

    strategyElementsSelector: '.strategy .strategy-element',
    otherStrategyElementsSelector: '.strategy .other-strategy-elements .strategy-element',
    moonOrbitSelector: '.strategy .moon-orbit',

    init: function () {

        this.initAnimation( 360, true, 5000, 'linear' );
        this.initHoverWatcher();
        this.initClickWatcher();

    },

    initAnimation: function ( rotateAngle, infinite, time, animation ) {

        var self = this;

        angle = this.getRotationAngle();

        $( this ).css( 'transform', 'rotate(' + angle + 'deg)' );

        $( this.moonOrbitSelector ).animate( { rotate: '0deg' }, 0 );
                
        $( this.moonOrbitSelector ).animate(
            {
                rotate: '+=' + parseInt( rotateAngle ) +'deg'
            }, 
            {
                step: function ( now ) {

                    now += angle;

                    $( this ).css( 'transform', 'rotate(' + now + 'deg)' );
                    
                },
                duration: time,
                easing: animation,
                complete: ( infinite ) ? self.initAnimation.bind( self, rotateAngle, infinite, 5000, 'linear' ) : ''
            }
        );

    },

    getRotationAngle: function () {
        
        var style = $( this.moonOrbitSelector ).attr( 'style' );

        if ( !style )
            return 0;

        var angle = parseInt( style.split( 'rotate(' )[1].split( 'deg' )[0] );

        if ( angle >= 360 )
            angle -= 360;

        return angle;
        
    },

    initHoverWatcher: function () {

        var self = this;

        $( this.otherStrategyElementsSelector ).hover(

            function () {

                $( self.moonOrbitSelector ).stop( true, false );

                var count = $( this ).data( 'count' );

                var targetedAngle = self.getSelectedElementAngle( count ) - self.getRotationAngle();

                self.initAnimation( targetedAngle, false, 500, 'easeOutQuad' );

            },

            function () {
                
                $( self.moonOrbitSelector ).stop( true, false );

                self.initAnimation( 360, true, 5000, 'linear' );

            }

        );

    },

    getSelectedElementAngle: function ( count ) {
        
        switch ( count ) {
            
            case 1:
                return 0;
            case 2:
                return 90;
            case 3:
                return 180;
            case 4:
                return 270;
            default:
                return 360;

        }

    },

    initClickWatcher: function () {

        var self = this;

        $( this.strategyElementsSelector ).click( function () {

            $( self.strategyElementsSelector ).not( $( this ) ).removeClass( 'active' );
            $( this ).addClass( 'active' );

            var targetedText = $( this ).data( 'name' );

            $( '.description-wrapper' ).not( '[data-name="' + targetedText + '"]' ).removeClass( 'active' );
            $( '[data-name="' + targetedText + '"]' ).addClass( 'active' );

        } );

    }

};

otherAboutUs = {

    containerSelector: '.others-about-us .logos',
    buttonContainerSelector: '.others-about-us .logos .others-logo', 
    buttonSelector: '.others-about-us .logos .others-logo button',
    quotesSelector: '.others-about-us .opinions .others-opinion',

    init: function () {

        this.initClickWatcher();
        this.initSlick();

    },

    initSlick: function() {

        var self = this;

        $( this.containerSelector ).slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 5
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2
                  }
                }
            ]
        });

        $(this.containerSelector).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            self.chooseSlide( nextSlide );
        });

    },

    initClickWatcher: function () {

        var self = this;

        $( this.buttonSelector ).click( function () {

            var number = $(this).closest('.others-logo').data('slick-index');
            
            self.chooseSlide( number );

        });

    },

    chooseSlide: function( slideNumber ) {

        $( this.buttonContainerSelector ).removeClass( 'active' );

        var buttonContainer =  $( this.buttonContainerSelector + '[data-slick-index = ' + slideNumber + ']' );

        $( buttonContainer ).addClass( 'active' );
        var companySlug = $( buttonContainer ).data( 'slug' );

        $( this.quotesSelector ).removeClass( 'active' );
        $( this.quotesSelector + '[data-slug="' + companySlug + '"]' ).addClass( 'active' );

    }

};

$( document ).ready( function () {

    projectRotator.init();
    whatDoWeDo.init();
    otherAboutUs.init();

} );