var popup = {

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $('#popup').on('click', '.close-popup', function() {

            self.hide();

        });

    },

    show: function() {

        $('#popup').fadeIn();

    },

    hide: function() {

        $('#popup').fadeOut();

    },

    toggle: function() {

        $('#popup').fadeToggle();

    }

};

$(document).ready(function() {

    popup._init();

});