var navigation = {

    lastNotedScroll: 0,

    navigationContainerClass: '.navigation',

    _init: function () {

        if( $( this.navigationContainerClass ).length ) { 
            this.scrollWatcher();
        }

    },

    scrollWatcher: function () {

        var self = this;

        $( window ).scroll( function () {

            var currentScroll = parseInt( $( window ).scrollTop() );

            self.toggleSticky( currentScroll );
            self.toggleShow( currentScroll );
            
        } );

    },

    toggleSticky: function ( currentScroll ) {

        var self = this;

        if ( currentScroll > 300 && !$( this.navigationContainerClass ).hasClass( 'floating-nav') ) {
            
            $( this.navigationContainerClass ).addClass( 'floating-nav' );        
            setTimeout( function () { $( self.navigationContainerClass ).addClass( 'sticky' ); }, 100 );

        } else if ( currentScroll <= 50 && $( this.navigationContainerClass ).hasClass( 'floating-nav') ) {

            $( this.navigationContainerClass ).removeClass( 'sticky' );            
            $( this.navigationContainerClass ).removeClass( 'floating-nav' ); 

        }

    },

    toggleShow: function ( currentScroll ) {

        if ( this.lastNotedScroll > currentScroll ) {
            $('body').addClass('sticky');
            $( this.navigationContainerClass ).addClass( 'show' );
        }
        else {
            $('body').removeClass('sticky');
            $( this.navigationContainerClass ).removeClass( 'show' );
        }

        this.lastNotedScroll = currentScroll;

    }

};

var metodologyNav = {

    navContainer: '.methodology .sticky-nav',
    scrumContainer: '.methodology .sections .section',

    scrollTop: 0,

    offsetTop1: 0,
    offsetTop2: 0,

    _init: function() {

        this.registerEvents();

        this.handleSticky();
        this.handleActive();

        this.calculateDimensions();

    },

    registerEvents: function() {

        var self = this;

        $( window ).resize( function() {

            self.calculateDimensions();
            self.handleSticky();
            self.handleActive();
        
        });

        $( window ).scroll( function( event ) {

            self.scrollTop = $( window ).scrollTop();

            self.handleSticky();
            self.handleActive();

        });

        $( '.offset-go-to-1' ).click( function() {

            $( 'html, body' ).animate({ scrollTop: self.offsetTop1 }, 1000, function() {
                navigation.toggleShow();
                navigation.toggleSticky();
            }); 

        });

        $( '.offset-go-to-2' ).click( function() {

            $( 'html, body' ).animate({ scrollTop: self.offsetTop2 }, 1000, function() {
                navigation.toggleShow();
                navigation.toggleSticky();
            });

        });

    },

    calculateDimensions: function() {

        this.offsetTop1 = $( this.navContainer ).offsetTop ;
        this.offsetTop2 = $( this.scrumContainer ) ;

    },

    handleSticky: function() {

        if( this.scrollTop > this.offsetTop1 ) {
            $( this.navContainer ).addClass( 'sticky' );
        } else {
            $( this.navContainer ).removeClass( 'sticky' );
        }

        if( this.scrollTop <= this.offsetTop1 ) {
            $( this.navContainer ).removeClass( 'sticky' );
        }

    },
    
    handleActive: function() {

        if( this.scrollTop >  ( this.offsetTop2 - ( $( window ).innerHeight() / 2 ) ) ) {
            $( this.navContainer ).removeClass( 'offset1' );
            $( this.navContainer ).addClass( 'offset2' );
        } else {
            $( this.navContainer ).removeClass( 'offset2' );
            $( this.navContainer ).addClass( 'offset1' );
        }



    }

}

$( document ).ready( function () {

    navigation._init();
    metodologyNav._init();

} );