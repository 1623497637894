$(document).ready(function(){

	$('.btn-ham').click( function( event ) {

		event.stopPropagation();

		$('.hamburger-design').toggleClass('is-active');


	});

});



