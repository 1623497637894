var stickyScroller = {
    
        scrollTopButton: {},
        windowHeight: 0,
    
        documentHeight: 0,
        footerHeight: 0,
        scrollTopToSticky: 0,
    
        _init: function() {
    
            var self = this;
    
            this.scrollTopButton = $('footer #scroll-up');
            this.countValues();
    
            this.registerEvents();
    
        },
        countValues: function() {
            this.windowHeight = $(window).height();
            this.documentHeight = document.body.offsetHeight;
            this.footerHeight = $('footer').outerHeight();
    
            this.scrollTopToSticky = this.documentHeight - this.windowHeight - this.footerHeight;
    
        },
    
        registerEvents: function() {
    
            var self = this;
    
            $(this.scrollTopButton).click(function() {
    
                $("html, body").animate({
                    scrollTop: 0
                }, 600);
    
            });
    
            $(window).resize(function() {
                self.countValues();
            });
    
            $(window).scroll(function() {
    
                var scrollTop = $(this).scrollTop();
    
                if (scrollTop > self.windowHeight)
                    $(self.scrollTopButton).addClass('visible');
                else
                    $(self.scrollTopButton).removeClass('visible');
    
                // if (scrollTop > self.scrollTopToSticky)
                //     $(self.scrollTopButton).addClass('sticky');
                // else
                //     $(self.scrollTopButton).removeClass('sticky');
    
            });
    
        }
    
    };
    
    $(document).ready(function() {
    
        stickyScroller._init();
    
    });