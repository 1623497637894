var career = {

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() { 

        var self = this;

        $('#career .list .job-details-container').on('click', '.close', function() {

            self.hideAllJobOfferts();
            $('#career .list .item').removeClass('active');

        });

        $('#career .list .item').click(function() { 

            self.toggleJobOffert($(this));

        });

        $('#career .list').on('click', '.apply-job', function() {

            var name = $(this).attr('data-name');
            $('.job-title').html(name).val(name);

            popup.show();

        });

        $('#career .apply-popup input[type="file"]').on('change', function(event) {

            var val = $(this).val().split('\\').pop();

            $(this).parent().find('label').html(val);

        });

    },

    toggleJobOffert: function(jobItem) {

        $('#career .list .item').not(jobItem).removeClass('active');
        $(jobItem).toggleClass('active');

        if ($(jobItem).hasClass('active')) {

            this.hideAllJobOfferts();

            var id = $(jobItem).attr('data-id');
            var jobDetailsContainer = this.findClosestJobOfferContainer(jobItem);

            this.renderJobOffert(jobDetailsContainer, id);

        } else {

            this.hideAllJobOfferts();

        }

    },

    findClosestJobOfferContainer: function(jobItem) {

        var windowWidth = $(window).width();
        var size = 'sm-size';

        if (windowWidth > 767)
            size = 'md-size'; 

        return $(jobItem).nextAll('.' + size).eq(0);

    },

    renderJobOffert: function(jobDetailsContainer, id) {

        var self = this;

        $.get("", {
                id: id
            },
            function(data, textStatus, jqXHR) {
                self.showJobOffert(data, jobDetailsContainer);
            },
            "text"
        );

    },

    showJobOffert: function(ajaxHtml, jobDetailsContainer) {

        $(jobDetailsContainer).html(ajaxHtml);
        main.transformSVGToInline();

        $(jobDetailsContainer).slideDown('slow');

        jQuery('body').animate({
            scrollTop: $(jobDetailsContainer).offset().top - 295
        }, 'middle');

    },

    hideAllJobOfferts: function() {

        $('.job-details-container:visible').each(function() {

            var jobDetailsContainer = $(this);

            $(jobDetailsContainer).slideUp('fast');

        });

    }

};

$(document).ready(function() {

    career._init();

});