$(document).ready(function(){

    var slideWrapper = $(".main-slider"),

    lazyCounter = 0;

    // When the slide is changing
    function playPauseVideo(slick, control){
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");
    slideType = currentSlide.attr("class").split(" ")[1];
    startTime = currentSlide.data("video-start");


    if (slideType === "video") {
        video = currentSlide.children("video").get(0);
        if (video != null) {
        if (control === "play"){
            video.play();
        } else {
            video.pause();
        }
        }
    }
    }

    // DOM Ready
    $(function() {
    // Initialize
    slideWrapper.on("init", function(slick){
        slick = $(slick.currentTarget);
        setTimeout(function(){
        playPauseVideo(slick,"play");
        }, 1000);
    });
    slideWrapper.on("beforeChange", function(event, slick) {
        slick = $(slick.$slider);
        playPauseVideo(slick,"pause");
    });
    slideWrapper.on("afterChange", function(event, slick) {
        slick = $(slick.$slider);
        playPauseVideo(slick,"play");
    });
    slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
        lazyCounter++;
        if (lazyCounter === lazyImages.length){
        lazyImages.addClass('show');
        // slideWrapper.slick("slickPlay");
        }
    });

    //start the slider
    slideWrapper.slick({
        dots: true,
        customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a>'+parseInt(i+1)+'</a>';
                    }
    });
    });
 
    //custom function showing current slide
    var $status = $('.pagingInfo');
    var $slickElement = $('.single-item');


    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text(i + '/' + slick.slideCount); 
    });


  });
