var recaptchaToken = null;
var cv = $("label[for='cv']").text();
var lm = $("label[for='lm']").text();

var onloadCallback = function() {
	
	if( $('#recaptcha-replacement').length ) { 

		grecaptcha.render('recaptcha-replacement', {
			"sitekey" : $('#recaptcha-replacement').attr('data-site-key'),	
			"callback": verifyCallback,
		});

	};

};

var verifyCallback = function(response) {
	recaptchaToken = response;
};

function showError( inputName, errorTxt ) {
		
	$('input[name=' + inputName + ']').parent().addClass('has-error');
	
}

$(document).on('change', 'input[type="file"]', function(event) {

    var val = $(this).val().split('\\').pop();

	if( !val ) { 
	   val = $('.file-container').data('label');
	}

	$(this).closest('.file-container').find('label').html(val);

});





$('.form-inline, .form-contact').submit( function( event ) {
	
	event.preventDefault();

	var formData = new FormData($(this)[0]);
	// if (!recaptchaToken) {	

	// }


	$.ajax({
		url: $('body').attr('data-root-url'),
		dataType: 'json',
		data: formData, 
		type: 'POST',
		async: false,
		cache: false,
		contentType: false,
		processData: false,
		success: function(response) {
            if (response.success) {
				var timer;
				var element = $('#notice');
				$('#captcha-invalid').removeClass('visible');
				element.addClass('visible');
				
				if(element.hasClass('visible')){
					
					timer = setTimeout(function () {
						element.removeClass('visible');
						
					},4000);
					
					$('#notice button').click(function(event){
							
						element.remove();
							
					});
				}

				$('#popup').fadeOut();
                $("input[type='text'], textarea, input[type='email'], input[type='file']").val('');
                $('input[type=checkbox]').prop('checked',false);
                $('label[for="cv"]').html(cv);
                $('label[for="lm"]').html(lm);
				
            } else {
				if( response.errors ) {
					
					Object.keys(response.errors).forEach(function (k) {
						showError( k , response.errors[k] );
					});
					
				}
				$('#captcha-invalid').addClass('visible');
            }
			grecaptcha.reset();
		}
	});
	
});