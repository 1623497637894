$(document).ready(function(){

	$('.close-responsive-nav').click( function( event ) {

		event.stopPropagation();

		$('.hamburger-design').removeClass('is-active');

	});

});
