var pleograf = {

    animationTime: 500,

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $("#pleograf-circle, #pleograf-accordion").on('shown.bs.collapse', function(){
            
            self.showElement($('.collapse.in').data('active'));

        });

        $("#pleograf-circle, #pleograf-accordion").on('hide.bs.collapse', function(){

            self.hideElement($('.collapse.in').data('active'));

        });
    
        $("#pleograf-circle, #pleograf-accordion").on('hidden.bs.collapse', function(){
            
            self.hideText();
    
        });

    },

    showElement: function( elementNumber ) {

        console.log(  $('.pleograf-element .text-' + elementNumber + ' .ghost' ) );
        console.log( '.pleograf-element .text-' + elementNumber + ' .ghost' )

        $('.pleograf-element .text' + elementNumber + ' .ghost' ).addClass('visible');
        
        $('.pleograf-element').addClass('visible-' + elementNumber);

    },

    hideElement: function( elementNumber ) {

        $('.pleograf-element .text' + elementNumber + ' .ghost' ).removeClass('visible');

    },

    hideText: function() {

        $('.pleograf-element').removeClass('visible-' + '1' );
        $('.pleograf-element').removeClass('visible-' + '2' );
        $('.pleograf-element').removeClass('visible-' + '3' );
        $('.pleograf-element').removeClass('visible-' + '4' );
        $('.pleograf-element').removeClass('visible-' + '5' );

    }

}



$(document).ready(function(){

    pleograf._init();

});