var form = {

    formSelector: '.contact-form form',

    isValid: true,

    init: function() {

        this.initFocused();
        this.setWatchers();

    },

    initFocused: function() {

        $(this.formSelector + ' input, ' + this.formSelector + ' textarea').each(function() {

            var value = $(this).val().trim();

            if (value == '')
                return;

            $(this).closest('label').addClass('focused');

        });

    },

    setWatchers: function() {

        var self = this;

        $(this.formSelector + ' input, ' + this.formSelector + ' textarea').focus(function() {

            $(this).closest('label').addClass('focused');

        });

        $(this.formSelector + ' input, ' + this.formSelector + ' textarea').focusout(function() {

            var value = $(this).val().trim();

            if (value != '')
                return;

            $(this).val('');
            $(this).closest('label').removeClass('focused');

        });

        $(this.formSelector).submit(function(ev) {

            self.isValid = true;

            self.validateData();


            if (!self.isValid) {
                $(self.formSelector + '.validated input, ' + self.formSelector + '.validated textarea').keyup(function() {

                    self.isValid = true;
                    self.validateData();

                });

                ev.preventDefault();

            }

        });

    },

    validateData: function() {

        var self = this;

        $(this.formSelector + ' .required input[type="text"], ' + this.formSelector + ' .required textarea').each(function(index, element) {

            if ($(element).val().trim().length) {

                $(element).closest('label').removeClass('error');
                return true;
            }

            $(element).closest('label').addClass('error');
            self.isValid = false;


        });


        $(this.formSelector + ' .required input[type="email"]').each(function(index, element) {

            if (self.validateEmail($(this).val())) {

                $(element).closest('label').removeClass('error');
                return true;

            }

            $(element).closest('label').addClass('error');
            self.isValid = false;


        });

        $(this.formSelector).addClass('validated');

    },

    validateEmail: function(email) {

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(email).toLowerCase());

    }

};

$(document).ready(function() {

    if ($('.contact-form form').length)
        form.init();

});