$(document).ready(function(){


        if ($(window).width() > 1440){	
            $("#accordion").find('.panel-collapse:first').addClass("in");
            $("#accordion").find('a:first').attr("aria-expanded","true");
            $(".collapse.in").each(function(){
                $(this).siblings(".panel-heading").find(".plus-minus").addClass("minus-to-plus").removeClass("plus-to-minus");
            });
        }	






    
    // Toggle plus minus icon on show hide of collapse element
    $(".collapse").on('show.bs.collapse', function(){
        $(this).parent().find(".plus-minus").removeClass("plus-to-minus").addClass("minus-to-plus");
    }).on('hide.bs.collapse', function(){
        $(this).parent().find(".plus-minus").removeClass("minus-to-plus").addClass("plus-to-minus");
    });
});
