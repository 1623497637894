var main = {

    _init: function() {


        this.transformSVGToInline();
        this.ieHack();

    },

    transformSVGToInline: function() {

        $('img.svg').each(function() {

            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgStyle = $img.attr('style');
            var imgURL = $img.attr('src');

	        $.ajax({
		        url: imgURL,
		        type: 'GET',
		        cache: false,
		        success: function(data) {

		        var $svg = $(data).find('svg');

		        if (typeof imgID !== 'undefined')
			        $svg = $svg.attr('id', imgID);

		        if (typeof imgClass !== 'undefined')
			        $svg = $svg.attr('class', imgClass + ' replaced-svg');

		        if (typeof imgStyle !== 'undefined') {
			        $svg = $svg.attr('style', imgStyle);

			        $svg.find('*').attr('style', imgStyle);
		        }

		        $svg = $svg.removeAttr('xmlns:a');
		        $img.replaceWith($svg);

	        }}, 'xml');

            // $.get(imgURL, functionx0(data) {
			//
            //     var $svg = $(data).find('svg');
			//
            //     if (typeof imgID !== 'undefined')
            //         $svg = $svg.attr('id', imgID);
			//
            //     if (typeof imgClass !== 'undefined')
            //         $svg = $svg.attr('class', imgClass + ' replaced-svg');
			//
            //     if (typeof imgStyle !== 'undefined') {
            //         $svg = $svg.attr('style', imgStyle);
			//
            //         $svg.find('*').attr('style', imgStyle);
            //     }
			//
            //     $svg = $svg.removeAttr('xmlns:a');
            //     $img.replaceWith($svg);
			//
            // }, 'xml');

        });

    },

    ieHack : function () {

        if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1 ){

            var siteAddress = $( 'body' ).data( 'address' );

            $( 'head' ).append( '<link rel="stylesheet" type="text/css" href="' + siteAddress + 'dist/css/style-ie.css" />' );

        }

    }

};


$(document).ready(function() {

    main._init();
});
