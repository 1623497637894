numericTabs = {

    buttonSelector: '.numeric-tabs .tab',
    contentSelector: '.numeric-tabs .content',


    init : function () {

        this.initClickWatcher();

    },

    initClickWatcher: function () {

        var self = this;

        $('.numeric-tabs .tab:first').addClass( 'active' );

        $('.numeric-tabs .content:first').addClass( 'active' );

        $( this.buttonSelector ).click( function () {

            $( self.buttonSelector ).closest( '.tab' ).removeClass( 'active' );
            $( this ).closest( '.tab' ).addClass( 'active' );

            var contentSlug = $( this ).closest( '.tab' ).data( 'slug' );
            console.log( contentSlug );
            

            $( self.contentSelector ).removeClass( 'active' );
            $( self.contentSelector + '[data-slug="' + contentSlug + '"]' ).addClass( 'active' );

        });

    }

};

$( document ).ready( function () {


    numericTabs.init();

} );